<template>
  <section class="invoice-add-wrapper">
    <b-row
      v-if="invoice"
      class="invoice-add"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/logo.png"
                      width="200px"
                      height="auto"
                    >
                    <h3 class="text-primary invoice-logo" />
                  </div>
                  <b-card-text class="mb-0">
                    RUE EL KOUNAITRA 1-dar Chaabene El Fehri
                  </b-card-text>
                  <b-card-text class="mb-0">
                    gsr-cle1@hotmail.fr
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +216 97 607 740
                  </b-card-text>
                  <b-card-text class="mb-0">
                    MF : 1082793F/A/M/000
                  </b-card-text>
                </div>
                <div>
                  <b-row>
                    <b-col>
                      <h4
                        style="color: black"
                        class="text-center"
                      >
                        Facture N°
                      </h4>
                    </b-col>
                    <b-col>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoice.number"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <h4
                        style="color: black"
                        class="text-center"
                      >
                        Date
                      </h4>
                    </b-col>
                    <b-col>
                      <flat-pickr
                        v-model="invoice.date"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                  <div>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="invoice.has_transportation_fee"
                      name="checkbox-1"
                    >
                      Facture comporte des frais de livraison de transport
                    </b-form-checkbox>
                    <b-col
                      v-if="invoice.has_transportation_fee"
                      cols="12"
                      lg="12"
                    >
                      <label class="d-inline d-lg-none">Frais de livraison</label>
                      <b-form-input
                        v-model="invoice.transportation_fee_amount"
                        value="7"
                        type="number"
                        class="mb-2"
                        placeholder="montant du frai de livraison"
                      />
                    </b-col>
                  </div>
                </div>
              </div>
            </b-card-body>
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row>

                <b-col
                  cols="12"
                  xl="6"

                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Client
                  </h6>
                  <v-select
                    v-model="invoice.customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customers"
                    label="company_name"
                    input-id="invoice-data-client"
                    :clearable="false"
                  />

                  <!-- Selected Client -->
                  <div
                    v-if="invoice.customer"
                    class="mt-1"
                  >
                    <h6
                      class="mb-25"
                    >
                      Client : {{ invoice.customer.company_name }}
                    </h6>
                  </div>
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoice.customer=null"
                />
                <b-col
                  cols="12"
                  xl="6"

                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Filters
                  </h6>
                  <v-select
                    v-model="invoice.tags"
                    required
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tags"
                    label="name"
                    :clearable="true"
                    multiple
                  />
                </b-col>
                <b-col
                  cols="12"
                  xl="6"

                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    TVA
                  </h6>
                  <v-select
                    v-model="invoice.vat"
                    required
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="vats"
                    label="value"
                    :clearable="true"
                  />
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                v-if="isLoading === true"
                class="text-center"
              >
                <b-spinner
                  large
                  variant="primary"
                />
              </div>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          Labelle
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Prix ttc
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="products"
                            label="label"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            disabled
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <b-form-input
                            v-model="item.produit.price_ttc"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p
                            v-if="item.produit.price_ttc"
                            class="mb-1"
                          >
                            {{ (item.produit.price_ttc * item.qty).toFixed(3) }}
                          </p>

                        </b-col>

                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeOld(index, item)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(item, index) in invoiceDataNew.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          Labelle
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Prix ttc
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty1"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Labelle</label>
                          <b-form-input
                            v-if="item.name == ''"
                            v-model="item.name"
                            class="mb-2"
                          />
                          <v-select
                            v-else
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="products"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="setSelected(item, index)"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <b-form-input
                            v-model="item.produit.sales_price_ttc"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">
                            {{
                              (item.produit.sales_price_ttc * item.qty1).toFixed(
                                3
                              )
                            }}
                          </p>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeNew(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Ajouter produit
              </b-button>
            </b-card-body>
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                />

                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Timbre fiscal:
                      </p>

                      <b-form-input
                        v-model="invoice.timbre_fiscale"
                        type="number"
                        class="mb-2"
                      />
                    </div>
                    <hr class="my-50">
                    <div
                      v-if="totalTTC"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        {{ Number(totalTTC)+Number(invoice.timbre_fiscale) }} DT
                      </p>
                    </div>
                    <div
                      v-else
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        0
                      </p>
                    </div>
                  </div></b-col></b-row>
            </b-card-body>
            <!-- Invoice Description: Total -->

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card>
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            @click="update"
            :class="{ 'disabled-cursor': isLoading || !isInvoiceValid }"
            :disabled="isLoading || !isInvoiceValid"
          >
            <b-spinner
              v-if="isLoading === true"
              small
            />
            Enregistrer
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  VBToggle,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'
import store from '@/store/index'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditInvoice',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: true,
      invoice: '',
      status: '',
      drivers: [],
      customers: [],
      states: [],
      last: [],
      removedItem: [],
      tags: [],
    }
  },

  computed: {
    ...mapGetters('vat', ['vats']),
    totalTTC() {
      const o = this.invoiceData.items.reduce(
        (accumulator, item) => accumulator + item.produit.price_ttc * item.qty,
        0,
      )
      if (this.invoiceDataNew.length !== 0) {
        const n = this.invoiceDataNew.items.reduce(
          (accumulator, item) => accumulator + item.produit.sales_price_ttc * item.qty1,
          0,
        )
        return o + n
      }
      return o
    },
    isInvoiceValid() {
      return (
        this.invoice.vat !== null
        && this.invoice.customer !== null
        && this.invoice.tags.length > 0
      )
    },
  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    this.getCustomers()
    this.getInvoice()
    this.getTags()
    this.invoiceData.items.shift()
    this.invoiceDataNew.items.shift()
    this.getVats()
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions('vat', ['getVats']),
    async getTags() {
      const { data } = await axiosIns.get('/tags/')
      this.tags = data
    },
    async getCustomers() {
      const { data } = await axiosIns.get('/customers/')
      this.customers = data
    },
    setSelected(value, index) {
      const f = this.invoiceDataNew.items.filter(
        item => item.produit.id === value.produit.id,
      )
      if (f.length > 1) {
        this.withTitle1(index, f, value.qty1)
      }
      const f1 = this.invoiceData.items.filter(
        item => item.produit.id === value.produit.id,
      )
      if (f1.length >= 1) {
        this.withTitle(index, f1, value.qty1)
      }
    },
    removeNew(index) {
      this.invoiceDataNew.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    withTitle(index, f, qty) {
      this.$swal({
        title: 'Produit déja ajouté au facture',
        text: `Voulez vous vraiment ajouter ${qty} au quantité déja existe`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeNew(index)
          // eslint-disable-next-line operator-assignment
          f[0].qty = f[0].qty + Number(qty)
          this.$swal({
            icon: 'success',
            title: 'Quantité augmenté!',
            text: `Quantité augmenté + ${qty}!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.removeNew(index)
        }
      })
    },
    withTitle1(index, f, qty) {
      this.$swal({
        title: 'Produit déja ajouté au facture',
        text: `Voulez vous vraiment ajouter ${qty} au quantité déja existe`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeNew(index)
          // eslint-disable-next-line operator-assignment
          f[0].qty1 = f[0].qty1 + Number(qty)
          this.$swal({
            icon: 'success',
            title: 'Quantité augmenté!',
            text: `Quantité augmenté + ${qty}!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.removeNew(index)
        }
      })
    },
    async getInvoice() {
      const { data } = await axiosIns.get(
        `/invoices/details/${this.$route.params.id}/`,
      )
      this.invoice = data

      if (this.invoice != null) {
        axiosIns
          .post('/invoices-products/details/', {
            invoice: this.invoice.id,
          })
          .then(res => {
            const result = res.data
            for (let i = 0; i < result.length; i += 1) {
              const itemFormBlankItem = {
                produit: result[i],
                qty: result[i].quantity,
                old_quantity: result[i].quantity,
              }

              this.invoiceData.items.push(
                JSON.parse(JSON.stringify(itemFormBlankItem)),
              )
            }
            this.isLoading = false
          })
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async update() {
      this.isLoading = true

      const convertedProducts = this.invoiceDataNew.items.map(product => ({
        id: product.produit.id, qty1: product.qty1, price_ttc: product.produit.sales_price_ttc, vat: product.produit.vat_value,
      }))
      const convertedProductsOld = this.invoiceData.items.map(product => ({
        id: product.produit.id, qty: product.qty, price_ttc: product.produit.price_ttc, vat: product.produit.vat,
      }))

      const obj = {
        id: this.invoice.id,
        number: this.invoice.number,
        timbre_fiscale: this.invoice.timbre_fiscale,
        date: this.invoice.date,
        total_ttc: this.totalTTC,
        products: convertedProducts,
        products_old: convertedProductsOld,
        customer: this.invoice.customer.id,
        removed_items: this.removedItem,
        has_transportation_fee: this.invoice.has_transportation_fee,
      }

      if (this.invoice.tags !== null) {
        obj.tags = this.invoice.tags.map(tag => tag.id)
      }

      if (this.invoice.vat !== null) {
        obj.vat = this.invoice.vat.id
      }

      if (this.invoice.transportation_fee_amount !== null) {
        obj.transportation_fee_amount = this.invoice.transportation_fee_amount
      }

      try {
        await axiosIns.put('/invoices/update/', obj)
        this.$router.push({ name: 'invoices' })
        this.showToast('success', 'top-center', 'Facture modifié avec avec succès')
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceDataNew.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItemNew)),
      )

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    async removeOld(index, item) {
      this.removedItem.push(item)
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    // UnRegister on leave

    const itemFormBlankItem = {
      produit: {
        reference: '',
      },
      qty: 0,
    }
    const itemFormBlankItemNew = {
      produit: {
        reference: '',
      },
      qty1: 1,
    }
    const invoices = ref([])
    const current = new Date()

    const invoiceData = ref({
      id: '',
      driver: null,
      date: `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceDataNew = ref({
      id: '',
      driver: null,
      date: `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    const products = ref([])
    axiosIns.get('/products/for-sales/').then(response => {
      products.value = response.data
    })

    const remise = 0

    const updateItemForm = (index, val) => {
      const { cost, qty } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
    }

    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer']

    return {
      invoiceData,
      invoiceDataNew,
      products,
      updateItemForm,
      itemFormBlankItemNew,
      itemFormBlankItem,
      paymentMethods,
      invoices,
      remise,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
